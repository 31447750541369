/**
 * @file    Balance.module.css
 *
 *          Stylesheet for balance component.
 *
 * @author  Kirill Kirnichansky <kirill@distributive.network>
 * @date    August 2022
 */

.dcc {
  display: flex;
  column-gap: 0.4em;
  justify-content: flex-end;
  align-items: baseline;
}

.dcc img {
  /* The natural aspect ratio of `credits-green.png`. */
  aspect-ratio: 5 / 4;
  object-fit: contain;
  max-height: 0.7em;
  width: auto;
  margin-inline-start: -0.2em;
}
